.counter {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: 1rem;
    flex-direction: column;
        background: linear-gradient(to right, #56ccf2, #2f80ed);
}

h1 {
    text-align: center;
}

.counter_output {
    font-size: 2rem;
}

button {
    font-size: 2rem;
    margin: 0.25rem;
    padding: auto;
}